const ready = (callback) => {

	if (document.readyState != 'loading') callback();
	else document.addEventListener('DOMContentLoaded', callback);

}


// Doc ready

ready(() => {

	const anchors 	= document.querySelectorAll('main a[href^="#"]:not([href="#"])');
	const topButton 		= document.getElementById('top');



	// // SMOOTH SCROLL TO ANCHORS

	anchors.forEach(trigger => {

		trigger.onclick = function(e) {

			e.preventDefault();
			let hash = this.getAttribute('href');
			let target = document.querySelector(hash);
			let elementPosition = target.offsetTop;
			let offsetPosition = elementPosition;

			window.scrollTo({

				top: offsetPosition,
				behavior: 'smooth'

			});
			
		};
	});


	// // BACK TO TOP

	function backToTop() {
		document.body.scrollTop = 0;
		document.documentElement.scrollTop = 0;
	}


	// // BACK TO TOP TOGGLE VISIBLITY

	function toggleBacktotop() {
		if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
			topButton.style.display = 'block';
		} else {
			topButton.style.display = 'none';
		}
	}

	topButton.addEventListener('click', backToTop);

	window.onscroll = function () {

		toggleBacktotop();

	};


});